.main_Chart {
  position: relative;
  width: 103%;
  height: 320px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

@media (min-width: 100px) and (max-width: 320px) {
  .main_Chart {
    height: 35vh;
    width: 44vh;
    margin-left: -10px;
  }

  .main_Chart canvas {
    width: 97% !important;
    height: auto !important;
  }
}

@media (min-width: 321px) and (max-width: 424px) {
  .main_Chart {
    height: 38vh;
    width: 49vh;
    margin-left: 1.5px;
  }

  .main_Chart canvas {
    width: 95% !important;
    height: auto !important;
  }
}

@media (min-width: 425px) and (max-width: 497px) {
  .main_Chart {
    height: 45vh;
    width: 57vh;
  }

  .main_Chart canvas {
    width: 95% !important;
    height: auto !important;
  }
}
@media (min-width: 498px) and (max-width: 767px) {
  .main_Chart {
    height: auto;
    width: 100%;
    margin-left: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .main_Chart {
    height: auto;
    width: 100%;
    margin-left: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .main_Chart {
    height: auto;
    width: 100%;
  }
}

@media (min-width: 1440px) and (max-width: 2000px) {
  .main_Chart {
    height: auto;
    width: 100%;
    margin-left: 10px;
  }
}

@media (min-width: 2001px) and (max-width: 2600px) {
  .main_Chart {
    height: auto;
    width: 100%;
    margin-left: 10px;
  }
}
